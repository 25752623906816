import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { HeaderComponent } from "./AccountInfo";
import { getLinks } from "../api/api";
import { getToken } from "../utils";

const PageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  @media (max-width: 600px) {
    display: flex;
    flex-wrap: nowrap;
    padding: 15px;
    flex-direction: column;
  }
`;

const LinkItem = styled.a`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 30%;
  height: 150px;
  margin: 5px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: #333;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 600px) {
    padding: 15px;
    max-width: 100%;
  }
`;

const LinkTitle = styled.h3`
  margin: 0;
  font-size: 1.6em;
  color: #007bff;

  @media (max-width: 600px) {
    font-size: 1.3em;
  }
`;

const LinkDescription = styled.p`
  margin: 10px 0 0;
  font-size: 1em;
  color: #555;

  @media (max-width: 600px) {
    font-size: 0.9em;
  }
`;

const ConnectionLinksComponent = () => {
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const fetchLinks = async () => {
      setLoading(true)
      const accessToken = getToken();
      const response = await getLinks (accessToken);
      setLinks(
        response.map((link, index) => ({
          ...link,
          id: index + 1,
        }))
      );
    setLoading(false)
    };
  
    useEffect(() => {
      fetchLinks();
    }, []);
  
  return (
    <>
      <HeaderComponent title={"Enabler Links"} />
      {loading ? <div>Loading...</div> : <PageContainer>
        {links.map((link, index) => (
          <LinkItem
            key={index}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkTitle>{link.title}</LinkTitle>
            <LinkDescription>{link.description}</LinkDescription>
          </LinkItem>
        ))}
      </PageContainer>
      }
    </>
  );
};

export default ConnectionLinksComponent;