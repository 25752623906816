import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { User, Phone, Mail } from "lucide-react";
import { useAppContext } from "../contexts/AppContext";
import { getAdCredentials, unlockUserAccount } from "../api/api";
import { toast } from "react-toastify";
import ResetPasswordModal from "./ResetPasswordModal";
import { darkenColor, getToken } from "../utils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #f5f5f5;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Card = styled.div`
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  margin: 10px;

  @media (min-width: 768px) {
    max-width: 50%;
  }
`;

const ProfileCard = styled(Card)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const Button = styled.button`
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 8px 15px;
  cursor: pointer;
  font-size: 1rem;
  background-color: ${({ backgroundColor }) => backgroundColor};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ backgroundColor }) =>
      backgroundColor && darkenColor(backgroundColor)};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

const Header = styled.h2`
  text-align: start;
  margin-bottom: 10px;
  border-bottom: 1px solid #f0f0f0;
  font-size: 1.5em;
  color: #333;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 1rem;
  color: #555;

  svg {
    margin-right: 10px;
    color: #888;
  }
`;

const FlexColumn = styled(Card)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin: 5px;
  border-radius: 5px;
  font-size: 1rem;
  color: #555;
`;

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #52b44b;
  color: white;
  padding: 10px 20px;
`;

const HeaderTitle = styled.h1`
  font-size: 1.8rem;
  margin: 0;
  font-weight: 600;
`;

export const HeaderComponent = ({title}) => (
  <HeaderContainer>
    <HeaderTitle>{title}</HeaderTitle>
  </HeaderContainer>
);

const AccountStatusButton = ({ loading, status, infor, onUnlock }) => {
  const backgroundColor = loading
    ? "gray"
    : status === "Yes"
    ? "green"
    : status === "No"
    ? "red"
    : "orange";
  const label = loading
    ? "Loading..."
    : status === "Yes"
    ? "Active"
    : status === "No"
    ? "Disabled"
    : status === "Locked"
    ? "Unlock Account"
    : "Unknown";

  return (
    <Button
      backgroundColor={backgroundColor}
      onClick={() => (status === "Locked" ? onUnlock(infor) : null)}
      disabled={loading}
    >
      {label}
    </Button>
  );
};

const PasswordResetButton = ({
  loading,
  handlePassDialog,
  passDialog,
  appUser,
}) => {
  const backgroundColor = loading ? "gray" : "orange";
  return (
    <Button
      backgroundColor={backgroundColor}
      onClick={() => appUser && handlePassDialog(!passDialog)}
      disabled={loading || !appUser}
    >
      {loading
        ? "Loading..."
        : !appUser
        ? "Could not load data"
        : "Reset/Update"}
    </Button>
  );
};

const PasswordExpiryButton = ({ loading, daysUntilExpiry }) => {
  const backgroundColor = loading
    ? "gray"
    : daysUntilExpiry > 5
    ? "green"
    : daysUntilExpiry < 1
    ? "red"
    : "orange";

  const label = loading
    ? "Loading..."
    : daysUntilExpiry < 1
    ? "Password has expired"
    : isNaN(daysUntilExpiry)
    ? "Could not load data"
    : `${daysUntilExpiry} day${daysUntilExpiry > 1 ? "s" : ""}`;

  return (
    <Button backgroundColor={backgroundColor} disabled={loading}>
      {label}
    </Button>
  );
};

const AccountInfo = () => {
  const { appUser, loading } = useAppContext();
  const [userInfo, setUserInfo] = useState({});
  const [loadInfo, setLoading] = useState(true);
  const [passDialog, setPasswordDialog] = useState(false);

  useEffect(() => {
    if (!loading) {
      fetchUserInfo();
    }
  }, [appUser, loading]);

  const fetchUserInfo = async () => {
    setLoading(true);
    try {
      const token = getToken();
      const info = await getAdCredentials(appUser.email.split("@")[0], token);
      setUserInfo(info.body);
    } catch (error) {
      console.error("Error fetching user info:", error);
    } finally {
      setLoading(false);
    }
  };

  const unlockAccount = async (info) => {
    setLoading(true);
    try {
      const accessToken = getToken();
      const data = await unlockUserAccount(info.username, accessToken);
      if (data === 200) {
        setUserInfo({ ...info, accountActive: "Yes" });
        toast.success("Account unlocked successfully");
      } else {
        toast.error("Account could not be unlocked");
      }
    } catch (error) {
      console.error("Error unlocking account:", error);
      toast.error("An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const daysUntilPasswordExpires = useMemo(() => {
    if (!userInfo.passwordExpires) return NaN;

    const expirationDate = new Date(userInfo.passwordExpires);
    const currentDate = new Date();

    const timeDifference = expirationDate - currentDate;
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  }, [userInfo.passwordExpires]);

  return (
    <>
      <HeaderComponent title={"Account Information"} />
      <Container>
        <ProfileCard>
          <Header>Profile</Header>
          <InfoItem>
            <User />
            {loading
              ? "Loading..."
              : appUser?.displayName || "Could not load data"}
          </InfoItem>
          <InfoItem>
            <Phone />
            {loading
              ? "Loading..."
              : appUser?.phoneNumber || "Could not load data"}
          </InfoItem>
          <InfoItem>
            <Mail />
            {loading ? "Loading..." : appUser?.email || "Could not load data"}
          </InfoItem>
        </ProfileCard>

        <FlexColumn>
          <Header>Account</Header>
          <List>
            <ListItem>
              <span>Account Status</span>
              <AccountStatusButton
                loading={loadInfo}
                status={userInfo.accountActive}
                infor={userInfo}
                onUnlock={unlockAccount}
              />
            </ListItem>
            <ListItem>
              <span>Password Expires In</span>
              <PasswordExpiryButton
                loading={loadInfo}
                daysUntilExpiry={daysUntilPasswordExpires}
              />
            </ListItem>
            <ListItem>
              <span>Reset AD password</span>
              <PasswordResetButton
                loading={loadInfo}
                handlePassDialog={setPasswordDialog}
                passDialog={passDialog}
                appUser={appUser}
              />
              {passDialog && (
                <ResetPasswordModal
                  passDialog={passDialog}
                  handlePassDialog={setPasswordDialog}
                />
              )}
            </ListItem>
          </List>
        </FlexColumn>
      </Container>
    </>
  );
};

export default AccountInfo;
