import { Link } from "react-router-dom";
import { SidebarItem } from "./Sidebar";
import * as Icons from "lucide-react";
import { useAppContext } from "../contexts/AppContext";
import { useEffect, useState } from "react";
import { getToken } from "../utils";
import { getAdCredentials } from "../api/api";

const SideNav = ({ menu }) => {
  const { user, appUser, loading } = useAppContext();
  const [userInfo, setUserInfo] = useState({});
  const [loadInfo, setLoading] = useState(true);

  const detectPlatform = () => {
    const platform = navigator.userAgentData.platform;
    if (platform.includes("Windows")) {
      return "Windows";
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (!loading && user) {
      fetchUserInfo();
    }
  }, [user, loading]);

  const fetchUserInfo = async () => {
    setLoading(true);
    try {
      const token = getToken();
      const info = await getAdCredentials(appUser.email.split("@")[0], token);
      setUserInfo(info.body);
    } catch (error) {
      console.error("Error fetching user info:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="menu">
      {menu.map((listItem) => {
        const IconComponent = Icons[listItem.icon] || Icons.FormInputIcon; // Default to FormInputIcon if not found

        return (
          <Link to={listItem.url} className="listItem" key={listItem.id}>
            <SidebarItem
              icon={<IconComponent size={18} />}
              text={
                <p className="font-futura-bold text-lg bold">
                  {listItem.title}
                </p>
              }
            />
          </Link>
        );
      })}

      {/* Only render extra items if userInfo is available and not loading */}
      {user && !loading && userInfo.globalGroupMemberships && (
        <>
          {(userInfo.globalGroupMemberships.includes("IT User Support") ||
            userInfo.globalGroupMemberships.includes("Workspace Automation")) && (
            <>
              <Link to={"upload"}>
                <SidebarItem
                  icon={<Icons.UploadCloudIcon size={18} />}
                  text={
                    <p className="font-futura-bold text-lg bold">Upload FAQ</p>
                  }
                />
              </Link>
              <Link to={"connection-links"}>
                <SidebarItem
                  icon={<Icons.LinkIcon size={18} />}
                  text={
                    <p className="font-futura-bold text-lg bold">
                      Enabler Links
                    </p>
                  }
                />
              </Link>
              <SidebarItem
                icon={<Icons.Database size={18} />}
                text={<p className="font-futura-bold text-lg bold">EUDA db</p>}
                onClick={() =>
                  window.open(
                    "https://eudadb-euda.apps.devocp.safaricom.net",
                    "_blank"
                  )
                }
              />
            </>
          )}
          {userInfo.globalGroupMemberships.includes("Workspace Automation") && (
            <>
              <Link to={"chat"}>
                <SidebarItem
                  icon={<Icons.MessageCircle size={18} />}
                  text={<p className="font-futura-bold text-lg bold">Chat</p>}
                />
              </Link>
              <Link
                to={"#"}
                onClick={() => window.open("/dashboard", "_blank")}
              >
                <SidebarItem
                  icon={<Icons.BookDashed size={18} />}
                  text={
                    <p className="font-futura-bold text-lg bold">
                      Admin Dashboard
                    </p>
                  }
                />
              </Link>
            </>
          )}
        </>
      )}

      {/* Platform-specific item */}
      {detectPlatform() === "Windows" && (
        <SidebarItem
          icon={<Icons.BoxesIcon size={18} />}
          text={
            <p className="font-futura-bold text-lg bold">Software Center</p>
          }
          onClick={() => (window.location.href = "softwarecenter: SoftwareID")}
        />
      )}
    </div>
  );
};

export default SideNav;