import { createContext, useState, useContext, useEffect } from 'react';
import { decodeToken, setToken, getToken, removeToken, isTokenExpired, getUsernameFromToken } from "../utils";
import { getUserDetails } from '../api/api';

export const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children }) => {
  
  const [user, setUser] = useState(null);
  const [appUser, setAppUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
     const fetchUserDetails = async (username) => {
       try {
         setLoading(true);
         const response = await getUserDetails(username);
         setAppUser(response);
       } catch (err) {
         setError("Failed to fetch user details");
         console.error(err);
       } finally {
         setLoading(false);
       }
     };

    useEffect(() => {
      const token = getToken();
         if (token) {
           const username = getUsernameFromToken(token);
           if (username) {
             fetchUserDetails(username);
           }
         } else {
           setLoading(false);
         }
    }, []);

  //// FROM HERE NEW

    useEffect(() => {
      const token = getToken();
      if (token) {
        if (isTokenExpired(token)) {
          removeToken()
          setUser({});
          setIsAuthenticated(false);
        } else {
          const decoded = decodeToken(token);
          if (decoded) {
            setUser(decoded);
            setIsAuthenticated(true);
          }
        }
      } else {
        setLoading(false);
      }
    }, []);
  
  

    const login = (token) => {
      setToken(token);
      const decoded = decodeToken(token);
      if (decoded) {
        setUser(decoded);
        setIsAuthenticated(true);
      }
    };

    const logout = () => {
      removeToken();
      setUser(null);
      setIsAuthenticated(false);
    };
    
    return (
      <AppContext.Provider
        value={{
          user,
          isAuthenticated,
          login,
          logout,
          appUser,
          loading,
          error,
          setAppUser,
        }}
      >
        {children}
      </AppContext.Provider>
    );
}