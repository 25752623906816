import React, { useEffect } from "react";
import styled from "styled-components";
import Info from "./Info";
import Details from "./Details";
import { useDeviceStatusContext } from "../../contexts/DeviceStatusContext";
import LoadingComponent from "../LoadingComponent";
import { useAppContext } from "../../contexts/AppContext";
import { HeaderComponent } from "../AccountInfo";

export const formattedDeviceStatus = (item) => {
  return [
    {
      title: "Device Model",
      description: item.deviceName,
      icon: "/images/Vector.png",
    },
    {
      title: "Serial Number",
      description: item.deviceSerialNumber,
      icon: "/images/serial.png",
    },
    {
      title: "Replacement Date",
      description: item.timestamp,
      icon: "/images/Calandar.png",
    },
    {
      title: "Asset Number",
      description: item.assetId,
      icon: "/images/assest.png",
    },
    {
      title: "ReqID",
      description: item.requestId,
      icon: "/images/statuss.png",
    },
    {
      title: "Status",
      description: item.status,
      icon: "/images/status.png",
    },
  ];
};

// Styled Components
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 100vh;
  overflow: auto;
  background: #f4f4f9;
`;

const DeviceCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin: 8px;
  padding: 16px;
  width: 500px;
`;

const DeviceTitle = styled.h3`
  margin: 8px 0;
  padding: 8px;
  font-family: "Futura, sans-serif";
  font-size: 16px;
  font-weight: bold;
`;

const NoDevicesMessage = styled.h3`
  text-align: center;
  margin-top: 20px;
  font-family: "Futura, sans-serif";
`;

export default function DeviceStatus() {
  const { state, fetchDeviceStatus } = useDeviceStatusContext();
  const { appUser, loading } = useAppContext();

  // Effect to fetch device status after appUser is available and loading is false
  useEffect(() => {
    if (!loading && appUser) {
      fetchDeviceStatus(appUser.email.split("@")[0]);
    }
  }, [appUser, loading]); // Depend on appUser, loading, and fetchDeviceStatus

  return (
    <div>
      <HeaderComponent title={"Device Status"} />
      <Info />

      <Container data-testid="d-status">
        {/* Check if loading is true or if state.loading is true */}
        {loading || state.loading ? (
          <LoadingComponent />
        ) : (
          <>
            {/* If no devices are available */}
            {state.status.length === 0 ? (
              <NoDevicesMessage>No device(s) available</NoDevicesMessage>
            ) : (
              // Map through devices and display them
              state.status.map((device, index) => (
                <DeviceCard key={index}>
                  {state.status.length > 1 && (
                    <DeviceTitle>{device.deviceName}</DeviceTitle>
                  )}
                  <div>
                    {formattedDeviceStatus(device).map((item, itemIndex) => (
                      <Details
                        key={itemIndex}
                        title={item.title}
                        description={item.description}
                        icon={item.icon}
                      />
                    ))}
                  </div>
                </DeviceCard>
              ))
            )}
          </>
        )}
      </Container>
    </div>
  );
}