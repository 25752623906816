import React, { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import { createCategory, getCategories, postFaq } from "../api/api";
import styled from "styled-components";
import Markdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import { useAppContext } from "../contexts/AppContext";
import { getToken } from "../utils";
import { HeaderComponent } from "./AccountInfo";

const DialogContainer = styled.div`
  background: #ffffff;
  padding: 32px;
  max-width: 80%;
  margin: 30px auto;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

const DialogTitle = styled.h2`
  margin: 0;
  font-size: 26px;
  font-weight: 600;
  color: #333;
`;

const DialogContent = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
`;

const DialogActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const InputField = styled.input`
  width: 100%;
  padding: 14px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #007bff;
    outline: none;
  }

  &::placeholder {
    color: #aaa;
  }
`;

const SelectField = styled.select`
  width: 100%;
  padding: 14px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  background-color: #fff;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const TextAreaField = styled.textarea`
  width: 100%;
  padding: 14px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  resize: vertical;

  &:focus {
    border-color: #007bff;
    outline: none;
  }

  &::placeholder {
    color: #aaa;
  }
`;

const Button = styled.button`
  padding: 12px 24px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  background-color: ${(props) => (props.primary ? "#007bff" : "#f0f0f0")};
  color: ${(props) => (props.primary ? "#fff" : "#333")};
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: ${(props) => (props.primary ? "#0056b3" : "#e0e0e0")};
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
`;

const ErrorText = styled.p`
  color: red;
  margin-top: 8px;
  font-size: 14px;
`;

const LoadingText = styled.p`
  color: #007bff;
  font-size: 14px;
  margin-top: 16px;
`;

const FaqUploadForm = () => {
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [customCategory, setCustomCategory] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const { appUser } = useAppContext();

  useEffect(() => {
    let isMounted = true;
    const accessToken = getToken();

    const fetchCategories = async () => {
      setLoading(true);
      try {
        const res = await getCategories(accessToken);
        if (isMounted) setCategories(formattedCategories(res));
      } catch (error) {
        if (isMounted) console.error("Error fetching categories: ", error);
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    fetchCategories();

    return () => {
      isMounted = false;
    };
  }, []);

  const formattedCategories = useCallback((data) => {
    return [
      { id: 1, value: "", label: "Select a category" },
      ...data.map((item, index) => ({
        id: index + 2,
        value: item.categoryName?.toLowerCase() || "",
        label: item.categoryName?.toUpperCase() || "Unknown",
      })),
      { id: data.length + 2, value: "other", label: "Other" },
    ];
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (!title || !description) {
        setError("Please fill in all fields.");
        return;
      }

      const accessToken = getToken();
      const selectedCategory = category === "other" ? customCategory : category;

      if (category === "other" && appUser?.email) {
        try {
          await createCategory(
            appUser.email.split("@")[0],
            customCategory,
            accessToken
          );
        } catch (error) {
          toast.error("Failed to create category. Please try again.");
          return;
        }
      }

      try {
        await postFaq({
          title,
          category: selectedCategory,
          description,
          adUsername: appUser.email.split("@")[0],
          accessToken,
        });

        setCategories((prevCategories) => [
          ...prevCategories.filter((cat) => cat.value !== "other"),
          {
            id: prevCategories.length + 1,
            value: selectedCategory,
            label: selectedCategory.toUpperCase(),
          },
          { id: prevCategories.length + 2, value: "other", label: "Other" },
        ]);

        toast.success("FAQ uploaded successfully");
        setTitle("");
        setCategory("");
        setCustomCategory("");
        setDescription("");
        setError(null);
      } catch (err) {
        toast.error("An error occurred. Please try again.");
      }
    },
    [title, category, customCategory, description, appUser]
  );

  const handleCategoryChange = useCallback((e) => {
    setCategory(e.target.value);
  }, []);

  return (
    <>
      <HeaderComponent title={"Uload FAQs"} />
      <div className="overflow-scroll w-full">
        <DialogContainer>
          <DialogTitle>Upload FAQ</DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              {error && <ErrorText>{error}</ErrorText>}
              <InputField
                type="text"
                placeholder="Title: Unable to setup MFA"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
              <SelectField
                value={category}
                onChange={handleCategoryChange}
                required
              >
                {loading ? (
                  <option disabled>Loading...</option>
                ) : (
                  categories.map((option) => (
                    <option key={option.id} value={option.value}>
                      {option.label}
                    </option>
                  ))
                )}
              </SelectField>
              {category === "other" && (
                <InputField
                  type="text"
                  placeholder="Enter custom category"
                  value={customCategory}
                  onChange={(e) => setCustomCategory(e.target.value)}
                  required
                />
              )}
              <TextAreaField
                placeholder="Description: Steps or description on how to resolve the FAQ"
                rows="4"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </DialogContent>
            <DialogActions>
              <Button primary type="submit">
                Upload
              </Button>
            </DialogActions>
          </form>
        </DialogContainer>
        {description && (
          <DialogContainer>
            <Markdown className="markdown" remarkPlugins={[remarkBreaks]}>
              {description}
            </Markdown>
          </DialogContainer>
        )}
      </div>
    </>
  );
};

export default FaqUploadForm;