import React from "react";
import { HeaderComponent } from "./AccountInfo";
export default function Webinar() {
  return (
    <>
      <HeaderComponent title={"Safaricom Webinar"} />
      <div className="w-full" style={{ height: "100vh"}}>
        <iframe
          src="https://outlook.office.com/book/SAFARICOMWEBINARS2@safaricomO365.onmicrosoft.com/?login_hint"
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </>
  );
}
