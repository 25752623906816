import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import { DeviceStatusContextProvider } from "./contexts/DeviceStatusContext";
import { AppContextProvider, useAppContext } from "./contexts/AppContext";
import { SidebarContextProvider } from "./contexts/SideBarContext";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import Home from "./dashboard/pages/home/Home";
import Users from "./dashboard/pages/users/Users";
import Products from "./dashboard/pages/products/Products";
import User from "./dashboard/pages/user/User";
import Product from "./dashboard/pages/product/Product";
import Chatbot from "./components/bot/ChatBot";
import DeviceStatus from "./components/device-status/DeviceStatus";
import Modal from "./components/Modal";
import Webinar from "./components/Webinar";
import AccountInfo from "./components/AccountInfo";
import ConnectionLinksComponent from "./components/ConnectionLinksComponent";
import FaqUploadForm from "./components/FaqUploadForm";
import Category from "./dashboard/pages/categories/Category";
import Faq from "./dashboard/pages/faqs/Faq";
import Links from "./dashboard/pages/links/Links";

function App() {
  const { user } = useAppContext();

  // if (user === null && getToken() === null) {
  //   return <div>Loading...</div>;
  // }

  const CustomerPage = () => (
    <AppContextProvider>
      <DeviceStatusContextProvider>
        <SidebarContextProvider>
          {!user ? <LoginPage /> : <HomePage />}
        </SidebarContextProvider>
      </DeviceStatusContextProvider>
    </AppContextProvider>
  );

  const ProtectedRoute = ({ children }) => {
    if (!user) {
      return <div>Loading...</div>
    }
    // if (!user) {
    //   return <Navigate to="/" />;
    // }
    if (user.roles !== "SUPER_ADMIN") {
      return <Navigate to="/" />;
    }
    return children;
  };

    const UserProtectedRoute = ({ children }) => {
      if (!user) {
        return <div>Loading...</div>;
      }
      if (!user) {
        return <Navigate to="/" />;
      }
      // if (user.roles === "USER") {
      //   return <Navigate to="/" />;
      // }
      return children;
    };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <CustomerPage />,
      children: [
        {
          path: "/chat",
          element: (
            <UserProtectedRoute>
              <Chatbot />
            </UserProtectedRoute>
          ),
        },
        { path: "/", element: <AccountInfo /> },
        { path: "/device-status", element: <DeviceStatus /> },
        { path: "/catalog", element: <Modal /> },
        { path: "/webinar", element: <Webinar /> },
        {
          path: "/upload",
          element: (
            <UserProtectedRoute>
              <FaqUploadForm />
            </UserProtectedRoute>
          ),
        },
        {
          path: "/connection-links",
          element: (
            <UserProtectedRoute>
              <ConnectionLinksComponent />
            </UserProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/dashboard",
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      ),
      children: [
        { path: "/dashboard", element: <Home /> },
        { path: "/dashboard/users", element: <Users /> },
        { path: "/dashboard/feedbacks", element: <Products /> },
        { path: "/dashboard/categories", element: <Category /> },
        { path: "/dashboard/links", element: <Links /> },
        { path: "/dashboard/faqs", element: <Faq /> },
        { path: "/dashboard/users/:id", element: <User /> },
        { path: "/dashboard/products/:id", element: <Product /> },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;