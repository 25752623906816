import { InvalidTokenError, jwtDecode } from "jwt-decode";

// Utility function to decode the JWT without verifying its signature
export const decodeToken = (token) => {
  try {
    return jwtDecode(token); // Decodes the token but does not verify
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
};

export const getUsernameFromToken = (token) => {
    if (!token) return null;
  const payload = decodeToken(token);
    return payload?.sub || null;
  };

// Utility function to get the JWT from localStorage
export const getToken = () => {
  return localStorage.getItem("authToken");
};

// Utility function to set the JWT in localStorage
export const setToken = (token) => {
  localStorage.setItem("authToken", token);
};

export const removeToken = () => {
  localStorage.removeItem("authToken");
};

export const isTokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime;
  } catch (error) {
    console.error("Invalid token", error);
    removeToken()
    return false;
  }
};

export function isValidInput(input) {
  if (input.length < 14) {
    return false;
  }

  let hasAlphanumeric = false;
  let hasSymbol = false;

  for (let i = 0; i < input.length; i++) {
    const char = input[i];

    if (
      (char >= "a" && char <= "z") ||
      (char >= "A" && char <= "Z") ||
      (char >= "0" && char <= "9")
    ) {
      hasAlphanumeric = true;
    }

    if (
      !(
        (char >= "a" && char <= "z") ||
        (char >= "A" && char <= "Z") ||
        (char >= "0" && char <= "9")
      ) &&
      char !== " "
    ) {
      hasSymbol = true;
    }

    if (hasAlphanumeric && hasSymbol) {
      return true;
    }
  }

  return hasAlphanumeric && hasSymbol;
}

export const darkenColor = (color, amount = 0.1) => {
  // Convert hex to RGB
  let hex = color.replace("#", "");
  let r = parseInt(hex.substr(0, 2), 16);
  let g = parseInt(hex.substr(2, 2), 16);
  let b = parseInt(hex.substr(4, 2), 16);

  // Darken the color by reducing the RGB values
  r = Math.max(0, r - Math.floor(r * amount));
  g = Math.max(0, g - Math.floor(g * amount));
  b = Math.max(0, b - Math.floor(b * amount));

  // Return the darkened color in hex format
  return `#${
    (1 << 24) + (r << 16) + (g << 8) + b.toString(16).slice(1).toUpperCase()
  }`;
};
